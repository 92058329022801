export const ADD_DEMO_FAUX = 'ADD_DEMO_FAUX';
export const ADD_DEMO_FAUXBATCH = 'ADD_DEMO_FAUXBATCH';
export const ASSOCIATE_NEWSKU = 'ASSOCIATE_NEWSKU';
export const ASSOCIATE_RELATEDPRODUCTS = 'ASSOCIATE_RELATEDPRODUCTS';
export const BLACKLIST_PRODUCT = 'BLACKLIST_PRODUCT';
export const CREATE_BP_BRAND = 'CREATE_BP_BRAND';
export const CREATE_BRANDS = 'CREATE_BRANDS';
export const CREATE_INTEGRATION = 'CREATE_INTEGRATION';
export const CREATE_LABELS = 'CREATE_LABELS';
export const CREATE_PO = 'CREATE_PO';
export const CREATE_USED = 'CREATE_USED';
export const CREDIT_USED = 'CREDIT_USED';
export const DELETE_INTEGRATION = 'DELETE_INTEGRATION';
export const FETCH_ARRIVALS = 'FETCH_ARRIVALS';
export const FETCH_AUDIT = 'FETCH_AUDIT';
export const FETCH_BLACKLISTPRODUCTS = 'FETCH_BLACKLISTPRODUCTS';
export const FETCH_BOUGHTTOGETHER = 'FETCH_BOUGHTTOGETHER';
export const FETCH_BPBRANDS = 'FETCH_BPBRANDS';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CONFIGURABLEPRODUCTS = 'FETCH_CONFIGURABLEPRODUCTS';
export const FETCH_DEMOS_FAUX = 'FETCH_DEMOS_FAUX';
export const FETCH_DEMOS_FAUXLIST = 'FETCH_DEMOS_FAUXLIST';
export const FETCH_DEMOS_REAL = 'FETCH_DEMOS_REAL';
export const FETCH_DEMOS_SUGGESTIONS = 'FETCH_DEMOS_SUGGESTIONS';
export const FETCH_DISCONTINUED = 'FETCH_DISCONTINUED';
export const FETCH_EBAY_ERRORS = 'FETCH_EBAY_ERRORS';
export const FETCH_EBAY_FAUXDEMOS = 'FETCH_EBAY_FAUXDEMOS';
export const FETCH_ETAAUDIT = 'FETCH_ETAAUDIT';
export const FETCH_INTEGRATIONS = 'FETCH_INTEGRATIONS';
export const FETCH_INVENTORY = 'FETCH_INVENTORY';
export const FETCH_LABELS = 'FETCH_LABELS';
export const FETCH_M2BRANDS = 'FETCH_M2BRANDS';
export const FETCH_MAGENTOAUDIT = 'FETCH_MAGENTOAUDIT';
export const FETCH_OVERSTOCKS = 'FETCH_OVERSTOCKS';
export const FETCH_PREORDERS = 'FETCH_PREORDERS';
export const FETCH_PRICEGUIDE = 'FETCH_PRICEGUIDE';
export const FETCH_PURCHASETOTALS = 'FETCH_PURCHASETOTALS';
export const FETCH_PURCHASING = 'FETCH_PURCHASING';
export const FETCH_RELATEDPRODUCTS = 'FETCH_RELATEDPRODUCTS';
export const FETCH_RELATEDSUGGESTIONS = 'FETCH_RELATEDSUGGESTIONS';
export const FETCH_REVERB = 'FETCH_REVERB';
export const FETCH_REVERBAUDIT = 'FETCH_REVERBAUDIT';
export const FETCH_SALECALC = 'FETCH_SALECALC';
export const FETCH_SALES = 'FETCH_SALES';
export const FETCH_STOCKCORRECTIONS = 'FETCH_STOCKCORRECTIONS';
export const FETCH_TOPSELLERS = 'FETCH_TOPSELLERS';
export const FETCH_TRACKING = 'FETCH_TRACKING';
export const FETCH_USED = 'FETCH_USED';
export const FETCH_USEDINVENTORYREPORT = 'FETCH_USEDINVENTORYREPORT';
export const FETCH_USEDNEWSKUREPORT = 'FETCH_USEDNEWSKUREPORT';
export const FETCH_USEDPIPELINEREPORT = 'FETCH_USEDPIPELINEREPORT';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_WAREHOUSES = 'FETCH_WAREHOUSES';
export const FULFILL_PREORDERS = 'FULFILL_PREORDERS';
export const PROCESS_TRANSFER = 'PROCESS_TRANSFER';
export const RUN_AUDIT = 'RUN_AUDIT';
export const RUN_MAGENTODEMODISABLE = 'RUN_MAGENTODEMODISABLE';
export const RUN_MAGENTOSALEDISABLE = 'RUN_MAGENTOSALEDISABLE';
export const SYNC_CATEGORIES = 'SYNC_CATEGORIES';
export const SYNC_COUPONS = 'SYNC_COUPONS';
export const SYNC_DEMOS = 'SYNC_DEMOS';
export const UPDATE_BRAND = 'UPDATE_BRAND';
export const UPDATE_BRANDPAGE = 'UPDATE_BRANDPAGE';
export const UPDATE_BRANDS = 'UPDATE_BRANDS';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_REVERB = 'UPDATE_REVERB';
export const UPDATE_USER = 'UPDATE_USER';
export const WEBHOOK_REVERB = 'WEBHOOK_REVERB';
export const UPDATE_USED = 'UPDATE_USED';
