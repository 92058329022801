import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createPurchaseOrder, fetchPurchaseTotals } from '../actions';
import ReactTable from 'react-table';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ExternalLink from './ExternalLink';
import Autocomplete from 'react-autocomplete';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LinearProgress from '@material-ui/core/LinearProgress';

class PurchasingForm extends Component {
  state = {
    responseModal: false,
    submitting: false,
    brandName: false,
    months: 2,
    response: '',
  };
  handleBrandChange = (e) => {
    this.setState({ brandName: e.target.value });
  };
  handleMonthChange = (e) => {
    this.setState({ months: e.target.value });
  };
  handleClose = () => {
    this.setState({ response: '', responseModal: false });
  };
  // this is different than the other demonize, as it only provides skus
  handleCreatePO = () => {
    if (this.state.brandName.length > 0) {
      this.setState({ submitting: true });
      let poBody = { brandName: this.state.brandName, months: this.state.months };
      this.props.createPurchaseOrder([poBody]).then(() => {
        this.setState({ submitting: false, brandName: false, months: 2, responseModal: true, response: this.props.purchasing });
      });
    }
  };
  nameSort(a, b) {
    if (a.toLowerCase < b.toLowerCase) {
      return -1;
    }
    if (a.toLowerCase > b.toLowerCase) {
      return 1;
    }
    return 0;
  }

  componentDidMount() {
    this.props.fetchPurchaseTotals();
  }

  render() {
    return (
      <div>
        {/* Response modal */}
        <Modal open={this.state.responseModal}>
          <Paper style={{ position: 'absolute', top: `20%`, left: `calc(50% - 500px)`, width: 1000, height: 400, outline: 0, padding: 25 }}>
            <ReactTable
              data={this.state.response}
              columns={[
                {
                  Header: 'Order',
                  accessor: 'id',
                  width: 100,
                  Cell: (row) => {
                    if (row.value) {
                      let url = `https://use1.brightpearlapp.com/patt-op.php?scode=invoice&oID=${row.value}`;
                      return <ExternalLink href={url} content={row.value} />;
                    } else {
                      return 'ERR';
                    }
                  },
                },
                { Header: 'Brand', accessor: 'name' },
              ]}
              style={{ height: '300px' }}
              className="-striped -highlight"
            />
            <Button style={{ marginTop: 15 }} onClick={this.handleClose} variant="raised" color="secondary">
              ok
            </Button>
          </Paper>
        </Modal>

        {/* Submission Form */}
        <div style={{ display: 'inline-block', padding: 25 }}>
          <div style={{ float: 'left', width: 700 }}>
            <form noValidate autoComplete="off">
              <FormControl style={{ marginRight: 15, marginBottom: 15, width: 400 }}>
                {this.props.brightpearl.purchasing && (
                  <Autocomplete
                    getItemValue={(item) => item.brandName}
                    items={[...new Set(this.props.brightpearl.purchasing.map((x) => x.brandName))]
                      .sort(this.nameSort)
                      .filter((item) => item != undefined)
                      .map((item) => ({ brandName: item }))}
                    renderItem={(item, isHighlighted) => <div style={{ padding: 6, background: isHighlighted ? 'lightgray' : 'white' }}>{item.brandName}</div>}
                    shouldItemRender={(item, value) => item.brandName.toLowerCase().indexOf(value.toLowerCase()) > -1}
                    value={this.state.brandSearch}
                    inputProps={{
                      placeholder: 'Search for a Brand',
                      style: { fontSize: 20, marginRight: 15, width: 370, fontFamily: 'Roboto', padding: 6, border: 0, borderBottom: '1px solid rgba(0, 0, 0, 0.42)' },
                    }}
                    onChange={(e) => this.setState({ brandSearch: e.target.value, brandName: false })}
                    onSelect={(val) => this.setState({ brandName: val, brandSearch: val })}
                    menuStyle={{ zIndex: 999, fontSize: '1rem', background: 'white', minWidth: 380, position: 'absolute', left: 'unset', top: 'unset', border: '1px solid gainsboro' }}
                  />
                )}
              </FormControl>
              <FormControl style={{ width: 100 }}>
                <InputLabel htmlFor="month"># Months</InputLabel>
                <Select
                  value={this.state.months}
                  onChange={this.handleMonthChange}
                  inputProps={{
                    name: 'Month',
                    id: 'month',
                  }}
                >
                  <MenuItem value={2}>2 mo</MenuItem>
                  <MenuItem value={3}>3 mo</MenuItem>
                  <MenuItem value={4}>4 mo</MenuItem>
                </Select>
              </FormControl>
              <Button onClick={this.handleCreatePO} variant="raised" disabled={!this.state.brandName || this.state.submitting} style={{ display: 'flex' }}>
                Create PO
                <CloudUploadIcon style={{ marginLeft: 10, color: '#092f89' }} />
              </Button>
              {this.state.submitting && <LinearProgress style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }} color="secondary" />}
            </form>
          </div>
          <div style={{ float: 'left', width: '%50' }}>
            <p style={{ margin: 0 }}>
              Schedule: <span style={{ fontWeight: 'bold' }}>{this.props.purchasing.purchaseTotals && this.props.purchasing.purchaseTotals.schedule}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ brightpearl, purchasing }) {
  return { brightpearl, purchasing };
}
const mapDispatchToProps = {
  createPurchaseOrder,
  fetchPurchaseTotals,
};
export default connect(mapStateToProps, mapDispatchToProps)(PurchasingForm);
