import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { fetchSales } from '../actions';

import { CartesianGrid, ComposedChart, Line, Area, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

class CustomizedAxisTick extends Component {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} size="12px" textAnchor="end" fill="#666" transform="rotate(-35)">
          {moment(payload.value * 1000).format('MM/DD/YY')}
        </text>
      </g>
    );
  }
}

class SalesModal extends Component {
  state = { sales: { dates: [], data: [] }, loading: true };
  componentDidMount() {
    this.props.fetchSales(this.props.bpId).then(() => {
      this.setState({ loading: false });
    });
  }
  render() {
    if (this.state.loading) {
      return <p style={{ textAlign: 'center' }}>Loading...</p>;
    }
    return (
      <div style={{ marginTop: '20px' }}>
        <ResponsiveContainer width="99%" minHeight={380}>
          <ComposedChart data={this.props.sales.data}>
            <CartesianGrid strokeDasharray="1 3" />
            <XAxis
              dataKey="date"
              type="number"
              ticks={this.props.sales.dates}
              allowDataOverflow={true}
              domain={[this.props.sales.dates[0], this.props.sales.dates[this.props.sales.dates.length - 1]]}
              // tickFormatter={tick => <text tranform="rotate(-35)">{moment(tick * 1000).format('MM/DD/YY')}</text>}
              tickCount={25}
              interval={0}
              tick={<CustomizedAxisTick />}
            />
            <YAxis allowDecimals={false} />
            <Area dataKey="thirtyDaySales" type="stepBefore" fill="#dcedc8" stroke="#aed581" />
            <Line type="stepAfter" connectNulls={true} dot={false} dataKey="qty" stroke="#34b4c9" />
            <Line type="monotone" connectNulls={true} dot={false} dataKey="trend" stroke="#FF8A65" />
            <Tooltip labelFormatter={label => moment(label * 1000).format('MM/DD/YY')} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

// SalesModal.propTypes = {
//   chartData: PropTypes.arrayOf(
//     PropTypes.shape({
//       time: PropTypes.number,
//       value: PropTypes.number
//     })
//   ).isRequired
// };

function mapStateToProps({ sales }) {
  return { sales };
}
const mapDispatchToProps = {
  fetchSales
};
export default connect(mapStateToProps, mapDispatchToProps)(SalesModal);
