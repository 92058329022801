import React, { Component } from 'react';
import { connect } from 'react-redux';
import { associateRelated } from '../actions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import MergeTypeIcon from '@material-ui/icons/MergeType';

import LinearProgress from '@material-ui/core/LinearProgress';

class RelatedForm extends Component {
  state = {
    successDialog: false,
    submitting: false,
    targetSku: '',
    relatedSkus: '',
  };
  handleNewInput = (e) => {
    if (e.target.id == 'targetSku') {
      this.setState({ targetSku: e.target.value });
    } else if (e.target.id == 'relatedSkus') {
      this.setState({ relatedSkus: e.target.value });
    }
  };

  handleClose = () => {
    this.setState({ successDialog: false });
  };

  handleAssociateSku = () => {
    if (this.state.targetSku.length > 0 && this.state.relatedSkus.length > 0) {
      this.setState({ submitting: true });
      this.props.associateRelated({ targetSku: this.state.targetSku, relatedSku: this.state.relatedSkus }).then(() => {
        this.setState({ submitting: false, successDialog: true, targetSku: '', relatedSkus: '' });
      });
    }
  };

  render() {
    return (
      <div>
        {/* association response modal */}

        <Dialog open={this.state.successDialog} onClose={this.handleClose}>
          <DialogTitle>Success!</DialogTitle>
          <DialogContent>
            <DialogContentText>Products associated.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button style={{ marginTop: 15 }} onClick={this.handleClose} variant="raised" color="secondary">
              yeah alright
            </Button>
          </DialogActions>
        </Dialog>

        <form style={{ padding: 25 }} noValidate autoComplete="off">
          <TextField
            value={this.state.targetSku}
            onChange={this.handleNewInput}
            id="targetSku"
            label="Target SKU"
            InputLabelProps={{ shrink: true }}
            placeholder="Single SKU"
            width="200px"
            margin="normal"
            style={{ margin: '0 100px 15px 0', width: 200 }}
          />
          <TextField
            value={this.state.relatedSkus}
            onChange={this.handleNewInput}
            id="relatedSkus"
            label="Related SKUs"
            InputLabelProps={{ shrink: true }}
            placeholder="Comma-separated list of related SKUs"
            margin="normal"
            style={{ margin: '0 100px 15px 0', width: 400 }}
          />
          <Button onClick={this.handleAssociateSku} variant="raised" disabled={this.state.submitting} style={{ marginRight: 15, background: '#f26322', display: 'flex' }}>
            Associate
            <MergeTypeIcon style={{ marginLeft: 10 }} />
          </Button>
          {this.state.submitting && <LinearProgress style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }} color="secondary" />}
        </form>
      </div>
    );
  }
}
function mapStateToProps({}) {
  return {};
}
const mapDispatchToProps = {
  associateRelated,
};
export default connect(mapStateToProps, mapDispatchToProps)(RelatedForm);
