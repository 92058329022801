import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { fetchReverb, updateReverb } from '../actions';
import 'react-table/react-table.css';
import PaperPage from './PaperPage';
import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import ReactTable from 'react-table';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import RefreshIcon from '@material-ui/icons/Refresh';
import ExitIcon from '@material-ui/icons/ExitToApp';
import DownloadIcon from '@material-ui/icons/FileDownload';

import TextField from '@material-ui/core/TextField';
import ExternalLink from './ExternalLink';
import LinearProgress from '@material-ui/core/LinearProgress';

import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

var tableColor = (status) => {
  if (status === 'skipped') {
    return red['500'];
  }
};

class Reverb extends Component {
  state = {
    submitting: false,
    skuList: [],
    response: '',
    modalOpen: false,
    loading: true,
    draft: 0,
  };

  styles = (theme) => ({
    button: {
      margin: theme.spacing.unit,
    },
    leftIcon: {
      marginRight: theme.spacing.unit,
    },
    rightIcon: {
      marginLeft: theme.spacing.unit,
    },
    iconSmall: {
      fontSize: 20,
    },
  });

  reverbColumns = [
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 120,
    },
    {
      Header: 'Title',
      accessor: 'title',
      Cell: (row) => (
        <ExternalLink
          href={`https://reverb.com/item/${row.original.slug}`}
          content={row.value}
        />
      ),
    },
    { Header: 'State', accessor: 'state', width: 60 },
    {
      Header: 'Condition',
      accessor: 'condition',
      width: 140,
    },
    {
      Header: 'Price',
      accessor: 'price',
      Cell: (row) => `$${row.value}`,
      width: 80,
    },
    {
      Header: 'QTY',
      accessor: 'inventory',
      width: 80,
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      Cell: (row) => format(row.value, 'MMM D h:mm A'),
      width: 180,
    },
  ];

  responseColumns = [
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 140,
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 115,
    },
    {
      Header: 'Description',
      accessor: 'msg',
      width: 740,
      Cell: (row) =>
        row.original.url === undefined ? (
          row.value
        ) : (
          <ExternalLink href={row.original.url} content={row.value} />
        ),
    },
  ];

  componentDidMount() {
    this._mounted = true;
    this.props.fetchReverb().then((resp) => {
      let count = this.props.reverb.listings.filter((line) => {
        return line.state == 'Draft';
      }).length;
      this.setState({
        loading: false,
        draft: count,
      });
    });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleSkuChange = (e) => {
    this.setState({ skuList: e.target.value });
  };

  update = () => {
    if (this.state.skuList.length > 0) {
      this.setState({ submitting: true });
      this.props.updateReverb(this.state.skuList).then(() => {
        this.setState(this.submitted);
      });
    }
  };

  submitted = () => {
    this.setState({
      submitting: false,
      skuList: [],
      modalOpen: true,
      response: this.props.reverb.response,
    });
  };

  closeModal = () => {
    this.setState({ modalOpen: false, submitting: false });
  };

  render() {
    return (
      <PaperPage xs={9}>
        {/* Response modal */}
        <Modal open={this.state.modalOpen}>
          <Paper
            style={{
              position: 'absolute',
              top: `20%`,
              left: `calc(50% - 500px)`,
              width: 1000,
              height: 400,
              outline: 0,
              padding: 25,
            }}>
            <ReactTable
              data={this.state.response}
              columns={this.responseColumns}
              style={{ height: '300px' }}
              className='-striped -highlight'
              getTrProps={(state, rowInfo, column) => {
                if (rowInfo)
                  return {
                    style: { background: tableColor(rowInfo.row.status) },
                  };
                else return {};
              }}
            />
            <Button
              style={{ marginTop: 15 }}
              onClick={this.closeModal}
              variant='raised'
              color='secondary'>
              ok
            </Button>
          </Paper>
        </Modal>

        {/* Header Bar */}
        <TopBar>
          <TopBarRow justify='flex-start'>
            <img
              src={
                'https://www.perfectcircuit.com/media/wysiwyg/reverbLogo.png'
              }
              alt='reverbLogo'
            />
            <Button
              variant='contained'
              href={'/api/reverb/csv'}
              label='Export Listings'
              disabled={this.state.submitting}
              style={{ marginLeft: 30 }}>
              Export Listings
              <DownloadIcon
                style={{ marginLeft: 10 }}
                className={this.styles.rightIcon}
              />
            </Button>
            <Button
              variant='contained'
              href={'https://reverb.com/my/selling/listings?state=draft'}
              label='View Drafts'
              disabled={this.state.submitting}
              style={{ marginLeft: 15 }}>
              View Drafts
              <ExitIcon
                style={{ marginLeft: 10 }}
                className={this.styles.rightIcon}
              />
            </Button>
          </TopBarRow>
        </TopBar>

        {/* Submission Form */}
        <form
          style={{ padding: 25 }}
          /*className={classes.container}*/ noValidate
          autoComplete='off'>
          <TextField
            value={this.state.skuList}
            onChange={this.handleSkuChange}
            id='sku'
            label='Upload SKUs'
            InputLabelProps={{ shrink: true }}
            placeholder='Comma separated list of SKUs'
            fullWidth
            margin='normal'
            style={{ marginBottom: 15 }}
          />
          <Button
            onClick={this.update}
            variant='raised'
            disabled={this.state.submitting}
            color='secondary'>
            Refresh
            <RefreshIcon
              style={{ marginLeft: 10 }}
              className={this.styles.rightIcon}
            />
          </Button>
          {this.state.submitting && (
            <LinearProgress
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
              }}
              color='secondary'
            />
          )}
        </form>

        {/* Listings */}
        <ReactTable
          data={this.props.reverb.listings}
          columns={this.reverbColumns}
          showPagination
          style={{ height: '550px' }}
          minRows={10}
          pageSize={100}
          className='-striped -highlight'
          filterable
          defaultFilterMethod={(filter, row) =>
            row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) >
            -1
          }
          loading={this.state.loading}
          loadingText='Loading...'
        />
      </PaperPage>
    );
  }
}

function mapStateToProps({ reverb }) {
  return { reverb };
}
const mapDispatchToProps = {
  fetchReverb,
  updateReverb,
};
export default connect(mapStateToProps, mapDispatchToProps)(Reverb);
