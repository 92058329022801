import React, { Component } from 'react';
import { connect } from 'react-redux';

import PaperPage from './PaperPage';
import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import UsedTable from './UsedTable';
import UsedForm from './UsedForm';
import UsedBarChart from './UsedBarChart';

import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/FileDownload';

class Used extends Component {
  state = { tabs: 0 };

  tabber = (event, value) => {
    this.setState({ tabs: value });
  };

  render() {
    return (
      <PaperPage xs={9} maxWidth={1900}>
        {/* Header Bar */}
        <TopBar pageName='Used Gear Intake'>
          <TopBarRow justify='flex-end'>
            <Button
              variant='contained'
              href={'/api/used-master-report?format=csv'}
              label='Export Listings'
              style={{ marginLeft: 30 }}>
              Export Products
              <DownloadIcon style={{ marginLeft: 10 }} />
            </Button>
          </TopBarRow>
        </TopBar>

        {/* Submission Form */}
        <UsedForm />
        <UsedBarChart />

        <UsedTable />
      </PaperPage>
    );
  }
}

export default connect()(Used);
