import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { cyan500, cyan700, pinkA200, grey100, grey300, grey400, grey500, white, darkBlack, fullBlack } from 'material-ui/styles/colors';
import { fade } from 'material-ui/utils/colorManipulator';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import { darken } from '@material-ui/core/styles/colorManipulator';

import App from './components/App';
import reducers from './reducers';

const palette = {
  primary1Color: cyan500,
  primary2Color: cyan700,
  primary3Color: grey400,
  accent1Color: pinkA200,
  accent2Color: grey100,
  accent3Color: grey500,
  textColor: darkBlack,
  secondaryTextColor: fade(darkBlack, 0.54),
  alternateTextColor: white,
  canvasColor: white,
  borderColor: grey300,
  disabledColor: fade(darkBlack, 0.3),
  pickerHeaderColor: cyan500,
  clockCircleColor: fade(darkBlack, 0.07),
  shadowColor: fullBlack,
};

const store = createStore(reducers, {}, applyMiddleware(reduxThunk));
// uncomment the following to print each state update to console for debugging
// store.subscribe(() => console.log(store.getState()));
const unsubscribe = store.subscribe(() => console.log(store.getState()));
function getTheme(uiTheme) {
  const theme = createMuiTheme({
    direction: uiTheme.direction,
    nprogress: {
      color: uiTheme.paletteType === 'light' ? '#000' : '#fff',
    },
    palette: { ...uiTheme.paletteColors, type: uiTheme.paletteType },
  });

  return theme;
}

const theme = getTheme({
  direction: 'ltr',
  paletteType: 'light',
  paletteColors: {
    primary: {
      main: grey['200'],
    },
    secondary: {
      // Darken so we reach the AA contrast ratio level.
      main: darken(blue.A400, 0.0),
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.querySelector('#root')
);
