import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';
import authReducer from './authReducer';
import userListReducer from './userListReducer';
import integrationReducer from './integrationReducer';
import auditReducer from './auditReducer';
import reverbAuditReducer from './reverbAuditReducer';
import magentoAuditReducer from './magentoAuditReducer';
import blacklistReducer from './blacklistReducer';
import arrivalsReducer from './arrivalsReducer';
import reverbReducer from './reverbReducer';
import brightpearlReducer from './brightpearlReducer';
import categoryReducer from './categoryReducer';
import demoReducer from './demoReducer';
import fauxDemoReducer from './fauxDemoReducer';
import fauxDemoResponseReducer from './fauxDemoResponseReducer';
import pricingReducer from './pricingReducer';
import m2BrandReducer from './m2BrandReducer';
import bpBrandReducer from './bpBrandReducer';
import ebayReducer from './ebayReducer';
import topSellerReducer from './topSellerReducer';
import purchasingReducer from './purchasingReducer';
import salesReducer from './salesReducer';
import trackingReducer from './trackingReducer';
import discontinuedReducer from './discontinuedReducer';
import transferReducer from './transferReducer';
import etaReducer from './etaReducer';
import relatedReducer from './relatedReducer';
import suggestedReducer from './suggestedReducer';
import configurableReducer from './configurableReducer';
import boughtTogetherReducer from './boughtTogetherReducer';
import stockCorrectionReducer from './stockCorrectionReducer';
import usedReducer from './usedReducer';
import inventoryReducer from './inventoryReducer';
import usedReportReducer from './usedReportReducer';

export default combineReducers({
  arrivals: arrivalsReducer,
  audit: auditReducer,
  auth: authReducer,
  blacklist: blacklistReducer,
  bpBrand: bpBrandReducer,
  brightpearl: brightpearlReducer,
  categories: categoryReducer,
  configurable: configurableReducer,
  demo: demoReducer,
  discontinued: discontinuedReducer,
  ebay: ebayReducer,
  etaAudit: etaReducer,
  fauxDemo: fauxDemoReducer,
  fauxDemoResponse: fauxDemoResponseReducer,
  form: reduxForm,
  integrations: integrationReducer,
  m2Brand: m2BrandReducer,
  magentoAudit: magentoAuditReducer,
  pricing: pricingReducer,
  purchasing: purchasingReducer,
  reverb: reverbReducer,
  reverbAudit: reverbAuditReducer,
  related: relatedReducer,
  sales: salesReducer,
  topSellers: topSellerReducer,
  tracking: trackingReducer,
  transfer: transferReducer,
  used: usedReducer,
  usedReport: usedReportReducer,
  users: userListReducer,
  boughtTogether: boughtTogetherReducer,
  suggested: suggestedReducer,
  stockCorrections: stockCorrectionReducer,
  inventory: inventoryReducer,
});
