import React, { Component } from 'react';
import { connect } from 'react-redux';
import { batchDemonize } from '../actions';
import ReactTable from 'react-table';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LinearProgress from '@material-ui/core/LinearProgress';

class DemoForm extends Component {
  state = {
    responseModal: false,
    submitting: false,
    skuList: '',
    response: ''
  };
  handleSkuChange = e => {
    this.setState({ skuList: e.target.value });
  };

  handleClose = () => {
    this.setState({ response: '', responseModal: false });
  };
  // this is different than the other demonize, as it only provides skus
  handleBatchDemonize = () => {
    console.log(this.state);
    if (this.state.skuList.length > 0) {
      this.setState({ submitting: true });
      this.props.batchDemonize(this.state.skuList).then(() => {
        console.log(this.props);
        this.setState({ submitting: false, skuList: '', responseModal: true, response: this.props.fauxDemoResponse });
      });
    }
  };

  render() {
    return (
      <div>
        <Modal open={this.state.responseModal}>
          <Paper style={{ position: 'absolute', top: `20%`, left: `calc(50% - 500px)`, width: 1000, height: 400, outline: 0, padding: 25 }}>
            <ReactTable
              data={this.state.response}
              columns={[
                { Header: 'SKU', accessor: 'sku', width: 100 },
                { Header: 'Name', accessor: 'name' },
                { Header: 'Status', accessor: 'status' }
              ]}
              style={{ height: '300px' }}
              className="-striped -highlight"
            />
            <Button style={{ marginTop: 15 }} onClick={this.handleClose} variant="raised" color="secondary">
              ok
            </Button>
          </Paper>
        </Modal>

        <form style={{ padding: 25 }} noValidate autoComplete="off">
          <TextField
            value={this.state.skuList}
            onChange={this.handleSkuChange}
            id="demo"
            label="Create Faux Demos"
            InputLabelProps={{ shrink: true }}
            placeholder="Comma separated list of SKUs"
            fullWidth
            margin="normal"
            style={{ marginBottom: 15 }}
          />
          <Button onClick={this.handleBatchDemonize} variant="raised" disabled={this.state.submitting} style={{ marginRight: 15, background: '#E53935' }}>
            Create
            <CloudUploadIcon style={{ marginLeft: 10 }} />
          </Button>
          {this.state.submitting && <LinearProgress style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }} color="secondary" />}
        </form>
      </div>
    );
  }
}
function mapStateToProps({ fauxDemoResponse }) {
  return { fauxDemoResponse };
}
const mapDispatchToProps = {
  batchDemonize
};
export default connect(mapStateToProps, mapDispatchToProps)(DemoForm);
