import React from 'react';
import ReactTable from 'react-table';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

import ExternalLink from './ExternalLink';

import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';
import PrintIcon from '@material-ui/icons/Print';

const UsedModal = (props) => {
  console.log(props);
  const { open, response, handleClose } = props;

  return (
    <Modal open={open}>
      <Paper
        style={{
          position: 'absolute',
          top: `15%`,
          left: `calc(50% - 500px)`,
          width: 1000,
          outline: 0,
          padding: 25,
        }}>
        <ReactTable
          data={response.messages}
          columns={[
            { Header: 'Stage', accessor: 'stage', width: 175 },
            {
              Header: 'Message',
              accessor: 'msg',
              width: 715,
              style: { whiteSpace: 'unset' },
              Cell: (row) => {
                if (row.original.link) {
                  return (
                    <ExternalLink
                      href={row.original.link}
                      content={row.value}
                    />
                  );
                }
                return row.value;
              },
            },
            {
              Header: 'Status',
              accessor: 'err',
              width: 80,
              style: { textAlign: 'center' },
              Cell: (row) =>
                row.value ? (
                  <ErrorIcon style={{ fontSize: 16 }} />
                ) : (
                  <CheckIcon style={{ fontSize: 16 }} />
                ),
            },
          ]}
          getTrProps={(state, rowInfo, column) => {
            if (rowInfo != undefined) {
              if (rowInfo.row._original.err) {
                return { style: { background: '#ea9999' } };
              } else return { style: { background: '#b6d7a8' } };
            }
            return {};
          }}
          style={{ height: '400px' }}
          className='-striped -highlight'
        />
        {!response.err && (
          <Button
            href={`https://use1.brightpearlapp.com/template_print.php?return-to-oid=${response.orderId}&template_id=105&oID=${response.orderId}&note_id=&return-to-oid=${response.orderId}`}
            target={'_blank'}
            variant='raised'
            style={{
              marginTop: 15,
              width: '100%',
              color: 'white',
              backgroundColor: 'green',
              textAlign: 'center',
            }}>
            <PrintIcon style={{ marginRight: 5 }} />
            <span>Print Confirmation</span>
          </Button>
        )}

        {response.err && (
          <div
            style={{
              width: '100%',
              boxSizing: 'border-box',
              marginTop: 20,
              padding: 15,
              background: 'red',
              color: 'white',
            }}>
            <p>
              {response.errMsg}. Please send a screenshot of the messages above
              to Harry.
            </p>
          </div>
        )}

        <Button
          style={{ marginTop: 15, display: 'block' }}
          onClick={handleClose}
          variant='raised'
          color='secondary'>
          ok
        </Button>
      </Paper>
    </Modal>
  );
};

export default UsedModal;
