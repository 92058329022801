import axios from 'axios';
import * as types from './types';

// ACTION CREATOR
// dispatch is a function, async before it defines it as asynchronous. our axios/ajax get call has await before it, defining it as a promise. when dispatch is called it waits for axios/res to resolve before calling itself
export const fetchUser = () => async (dispatch) => {
  // no need for curlys and return since just returning a single function
  const res = await axios.get('/api/current_user');

  dispatch({ type: types.FETCH_USER, payload: res.data });
};

export const fetchUserList = () => async (dispatch) => {
  const res = await axios.get('/api/users');

  dispatch({ type: types.FETCH_USERS, payload: res.data });
};

export const toggleAdmin = (id, admin) => async (dispatch) => {
  const res = await axios.post('/api/users/admin', { id, admin });
  dispatch({ type: types.UPDATE_USER, payload: res.data });
};

export const fetchIntegrations = () => async (dispatch) => {
  const res = await axios.get('/api/integrations');

  dispatch({ type: types.FETCH_INTEGRATIONS, payload: res.data });
};
export const deleteIntegration = (id) => async (dispatch) => {
  const res = await axios.post('/api/integrations/delete', { id });

  dispatch({ type: types.DELETE_INTEGRATION, payload: res.data });
};

export const createIntegration = (description) => async (dispatch) => {
  const res = await axios.post('/api/integrations', { description });

  dispatch({ type: types.CREATE_INTEGRATION, payload: res.data });
};

// Sync

export const dbCategories = () => async (dispatch) => {
  const res = await axios.get('/api/magento/categories/sync');
  dispatch({ type: types.SYNC_CATEGORIES, payload: res.data });
};

export const syncCoupons = () => async (dispatch) => {
  const res = await axios.get('/api/magento/coupons/sync');
  dispatch({ type: types.SYNC_COUPONS, payload: res.data });
};

// Magento

export const fetchCategories = () => async (dispatch) => {
  const res = await axios.get('/api/magento/categories');
  dispatch({ type: types.FETCH_CATEGORIES, payload: res.data });
};
export const updateCategory = (body) => async (dispatch) => {
  const res = await axios.put('/api/magento/categories', body);
  dispatch({ type: types.UPDATE_CATEGORY, payload: res.data });
};
export const createM2Brands = (brands) => async (dispatch) => {
  const res = await axios.post('/api/brands/m2', { brands });
  dispatch({ type: types.CREATE_BRANDS, payload: res.data });
};
export const fetchM2Brands = () => async (dispatch) => {
  const res = await axios.get('/api/brands/m2/table');

  dispatch({ type: types.FETCH_M2BRANDS, payload: res.data });
};
export const createLabels = (labels) => async (dispatch) => {
  const res = await axios.post('/api/labels/create', { labels });
  dispatch({ type: types.CREATE_LABELS, payload: res.data });
};
export const fetchLabels = () => async (dispatch) => {
  const res = await axios.get('/api/labels/table');

  dispatch({ type: types.FETCH_LABELS, payload: res.data });
};
export const updateBrandPage = () => async (dispatch) => {
  const res = await axios.get('/api/magento/refresh-brand-page');

  dispatch({ type: types.UPDATE_BRANDPAGE, payload: res.data });
};

//used
export const fetchUsedMasterReport = () => async (dispatch) => {
  const res = await axios.get('/api/used-master-report');

  dispatch({ type: types.FETCH_USED, payload: res.data });
};
export const fetchUsedPipelineReport = () => async (dispatch) => {
  const res = await axios.get('/api/used-pipeline-report');

  dispatch({ type: types.FETCH_USEDPIPELINEREPORT, payload: res.data });
};

export const fetchUsedInventoryReport = () => async (dispatch) => {
  const res = await axios.get('/api/used-inventory-report');

  dispatch({ type: types.FETCH_USEDINVENTORYREPORT, payload: res.data });
};

export const fetchUsedNewSkuReport = () => async (dispatch) => {
  const res = await axios.get('/api/used-newsku-report');

  dispatch({ type: types.FETCH_USEDNEWSKUREPORT, payload: res.data });
};

export const associateNewSku = (body) => async (dispatch) => {
  const res = await axios.post('/api/used-associate-newsku', {
    skuList: body,
  });
  dispatch({ type: types.ASSOCIATE_NEWSKU, payload: res.data });
};

export const createUsed = (body) => async (dispatch) => {
  const res = await axios.post('/api/used', body);
  dispatch({ type: types.CREATE_USED, payload: res.data });
};

export const applyUsedCredit = (body) => async (dispatch) => {
  const res = await axios.post('/api/used-credit', body);
  dispatch({ type: types.CREDIT_USED, payload: res.data });
};

export const updateUsedProduct = (body) => async (dispatch) => {
  const res = await axios.post('/api/used-update', body);
  dispatch({ type: types.UPDATE_USED, payload: res.data });
};

// Audit

export const fetchAudit = () => async (dispatch) => {
  const res = await axios.get('/api/audit/stock');

  dispatch({ type: types.FETCH_AUDIT, payload: res.data });
};

export const runAudit = () => async (dispatch) => {
  const res = await axios.get('/api/audit/run');

  dispatch({ type: types.RUN_AUDIT, payload: res.data });
};

export const fetchEtaAudit = () => async (dispatch) => {
  const res = await axios.get('/api/audit/eta');

  dispatch({ type: types.FETCH_ETAAUDIT, payload: res.data });
};

export const fetchReverbAudit = () => async (dispatch) => {
  const res = await axios.get('/api/audit/reverb');

  dispatch({ type: types.FETCH_REVERBAUDIT, payload: res.data });
};
export const fetchMagentoAudit = () => async (dispatch) => {
  const res = await axios.get('/api/audit/magento');

  dispatch({ type: types.FETCH_MAGENTOAUDIT, payload: res.data });
};

export const magentoDisableAudit = () => async (dispatch) => {
  const res = await axios.get('/api/audit/magento/demo-disable');

  dispatch({ type: types.RUN_MAGENTODEMODISABLE, payload: res.data });
};
export const magentoSaleAudit = () => async (dispatch) => {
  const res = await axios.get('/api/audit/magento/sale-disable');

  dispatch({ type: types.RUN_MAGENTOSALEDISABLE, payload: res.data });
};
export const fetchBlacklistProducts = () => async (dispatch) => {
  const res = await axios.get('/api/blacklist/products');

  dispatch({ type: types.FETCH_BLACKLISTPRODUCTS, payload: res.data });
};

export const fetchDiscontinuedAudit = () => async (dispatch) => {
  const res = await axios.get('/api/audit/magento/discontinued-audit');

  dispatch({ type: types.FETCH_DISCONTINUED, payload: res.data });
};

export const fetchBPBrands = () => async (dispatch) => {
  const res = await axios.get('/api/brands');

  dispatch({ type: types.FETCH_BPBRANDS, payload: res.data });
};

export const fetchWarehouses = () => async (dispatch) => {
  const res = await axios.get('/api/warehouses');

  dispatch({ type: types.FETCH_WAREHOUSES, payload: res.data });
};
export const fetchInventory = () => async (dispatch) => {
  const res = await axios.get('/api/inventory');

  dispatch({ type: types.FETCH_INVENTORY, payload: res.data });
};

export const updateBrands = (body) => async (dispatch) => {
  const res = await axios.post('/api/brands', { brandUpdate: body });

  dispatch({ type: types.UPDATE_BRANDS, payload: res.data });
};

export const createBpBrand = (brandName, vendorId) => async (dispatch) => {
  const res = await axios.post('/api/brands/bp', {
    brandName: brandName,
    vendorId: vendorId,
  });

  dispatch({ type: types.CREATE_BP_BRAND, payload: res.data });
};

export const fetchPreorders = () => async (dispatch) => {
  const res = await axios.get('/api/preorders');

  dispatch({ type: types.FETCH_PREORDERS, payload: res.data });
};

export const blacklistProduct = (body) => async (dispatch) => {
  const res = await axios.put('/api/blacklist/product', body);
  dispatch({ type: types.BLACKLIST_PRODUCT, payload: res.data });
};

export const updateBrand = (body) => async (dispatch) => {
  const res = await axios.put('/api/blacklist/brand', body);

  dispatch({ type: types.UPDATE_BRAND, payload: res.data });
};

export const fetchSaleCalc = (body) => async (dispatch) => {
  const res = await axios.get('/api/sale', {
    params: { type: 'table', percentage: body },
  });

  dispatch({ type: types.FETCH_SALECALC, payload: res.data });
};

export const fetchOverstocks = () => async (dispatch) => {
  const res = await axios.get('/api/overstocks');

  dispatch({ type: types.FETCH_OVERSTOCKS, payload: res.data });
};

export const fetchPriceGuide = () => async (dispatch) => {
  const res = await axios.get('/api/price-guide');

  dispatch({ type: types.FETCH_PRICEGUIDE, payload: res.data });
};

export const fetchTopSellers = (body) => async (dispatch) => {
  const res = await axios.get('/api/audit/top-sellers/table');

  dispatch({ type: types.FETCH_TOPSELLERS, payload: res.data });
};

export const fetchBoughtTogether = (body) => async (dispatch) => {
  const res = await axios.put('/api/bought-together', { sku: body });

  dispatch({ type: types.FETCH_BOUGHTTOGETHER, payload: res.data });
};
//related
export const fetchRelated = () => async (dispatch) => {
  const res = await axios.get('/api/magento/related/table');

  dispatch({ type: types.FETCH_RELATEDPRODUCTS, payload: res.data });
};

export const fetchRelatedSuggestions = () => async (dispatch) => {
  const res = await axios.get('/api/magento/related-suggestions/table');

  dispatch({ type: types.FETCH_RELATEDSUGGESTIONS, payload: res.data });
};

export const associateRelated = (body) => async (dispatch) => {
  const res = await axios.put('/api/magento/related', body);

  dispatch({ type: types.ASSOCIATE_RELATEDPRODUCTS, payload: res.data });
};

//config
export const fetchConfigurables = () => async (dispatch) => {
  const res = await axios.get('/api/magento/config/table');

  dispatch({ type: types.FETCH_CONFIGURABLEPRODUCTS, payload: res.data });
};

// purchasing
export const fetchPurchasing = (body) => async (dispatch) => {
  const res = await axios.get('/api/purchasing/report/table');

  dispatch({ type: types.FETCH_PURCHASING, payload: res.data });
};

export const fetchPurchaseTotals = (body) => async (dispatch) => {
  const res = await axios.get('/api/purchasing/totals');
  dispatch({ type: types.FETCH_PURCHASETOTALS, payload: res.data });
};

export const createPurchaseOrder = (body) => async (dispatch) => {
  const res = await axios.put('/api/purchasing/order', body);

  dispatch({ type: types.CREATE_PO, payload: res.data });
};

export const fetchSales = (body) => async (dispatch) => {
  const res = await axios.get('/api/sales/' + body);

  dispatch({ type: types.FETCH_SALES, payload: res.data });
};

// Demos

export const fetchFauxDemos = (body) => async (dispatch) => {
  const res = await axios.get('/api/demo?type=faux');
  dispatch({ type: types.FETCH_DEMOS_FAUX, payload: res.data });
};

export const fetchSuggestedDemos = (body) => async (dispatch) => {
  const res = await axios.get('/api/demo?type=suggested');
  dispatch({ type: types.FETCH_DEMOS_SUGGESTIONS, payload: res.data });
};

export const fetchFauxDemoList = (body) => async (dispatch) => {
  const res = await axios.get('/api/demo?type=fauxList');
  dispatch({ type: types.FETCH_DEMOS_FAUXLIST, payload: res.data });
};

export const fetchRealDemos = (body) => async (dispatch) => {
  const res = await axios.get('/api/demo?type=real');
  dispatch({ type: types.FETCH_DEMOS_REAL, payload: res.data });
};

export const demonize = (body) => async (dispatch) => {
  const res = await axios.put('/api/demo/add', body);
  dispatch({ type: types.ADD_DEMO_FAUX, payload: res.data });
};
export const batchDemonize = (body) => async (dispatch) => {
  const res = await axios.put('/api/demo/add?batch=true', { body });
  dispatch({ type: types.ADD_DEMO_FAUXBATCH, payload: res.data });
};

export const syncDemos = (body) => async (dispatch) => {
  const res = await axios.get('/api/audit/demo/sync');
  dispatch({ type: types.SYNC_DEMOS, payload: res.data });
};

// eBay

export const fetchEbayErrors = () => async (dispatch) => {
  const res = await axios.get('/api/ebay/errors');

  dispatch({ type: types.FETCH_EBAY_ERRORS, payload: res.data });
};

export const fetchEbayFauxDemos = () => async (dispatch) => {
  const res = await axios.get('/api/ebay/listings?fauxDemo=true');

  dispatch({ type: types.FETCH_EBAY_FAUXDEMOS, payload: res.data });
};

// Arrivals + tracking

export const fetchArrivals = () => async (dispatch) => {
  const res = await axios.get('/api/arrivals');

  dispatch({ type: types.FETCH_ARRIVALS, payload: res.data });
};

export const fetchTracking = () => async (dispatch) => {
  const res = await axios.get('/api/tracking');

  dispatch({ type: types.FETCH_TRACKING, payload: res.data });
};

export const processTransfer = (body) => async (dispatch) => {
  const res = await axios.put('/api/brightpearl/transfer', body);

  dispatch({ type: types.PROCESS_TRANSFER, payload: res.data });
};

export const fulfillPreorders = () => async (dispatch) => {
  const res = await axios.get('/api/preorders/fulfill');

  dispatch({ type: types.FULFILL_PREORDERS, payload: res.data });
};

export const fetchStockCorrections = () => async (dispatch) => {
  const res = await axios.get('/api/stock-corrections/table');

  dispatch({ type: types.FETCH_STOCKCORRECTIONS, payload: res.data });
};

// Reverb

export const webhookReverb =
  (accountCode, resourceType, id, lifecycleEvent, fullEvent) =>
  async (dispatch) => {
    const res = await axios.put('/api/webhook', {
      accountCode,
      resourceType,
      id,
      lifecycleEvent,
      fullEvent,
    });

    dispatch({ type: types.WEBHOOK_REVERB, payload: res.data });
  };

export const fetchReverb = () => async (dispatch) => {
  const res = await axios.get('/api/reverb');
  dispatch({ type: types.FETCH_REVERB, payload: res.data });
};

export const updateReverb = (skuList) => async (dispatch) => {
  const res = await axios.put('/api/reverb/update', { skuList });
  dispatch({ type: types.UPDATE_REVERB, payload: res.data });
};
