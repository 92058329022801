import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchPurchasing } from '../actions';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import ExternalLink from './ExternalLink';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SalesModal from './SalesModal';
class PurchasingTable extends Component {
  state = {
    loading: true,
    expanded: {},
  };

  numFilt = (filter, row) => {
    let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
    if (parseInt(filter.value) < 0) {
      return rowValue < parseInt(filter.value);
    }
    return rowValue > parseInt(filter.value);
  };

  statusFilter = ({ filter, onChange }) => (
    <select
      onChange={(event) => onChange(event.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : 'all'}>
      <option value='all'>All</option>
      <option value='Enabled'>Enabled</option>
      <option value='Not Found'>Not Found</option>
    </select>
  );

  susFilter = ({ filter, onChange }) => (
    <select
      onChange={(event) => onChange(event.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : 'all'}>
      <option value='all'>All</option>
      <option value='HI'>High Estimate</option>
      <option value='LO'>Low Estimate</option>
      <option value='NU'>New Item</option>
      <option value='PO'>Preorder</option>
    </select>
  );

  scheduleFilter = ({ filter, onChange }) => (
    <select
      onChange={(event) => onChange(event.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : 'all'}>
      <option value='all'>All</option>
      <option value='I'>I</option>
      <option value='II'>II</option>
      <option value='III'>III</option>
      <option value='IV'>IV</option>
    </select>
  );

  per = (value) => {
    return Math.floor(value * 100) + '%';
  };

  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  purchasingColumns = [
    {
      Header: 'Info',
      columns: [
        {
          Header: 'SKU',
          accessor: 'sku',
          style: { textAlign: 'left' },
          width: 120,
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            return (
              rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
            );
          },
          Cell: (row) => {
            if (row.value) {
              let url = `https://use1.brightpearlapp.com/p.php?p=report&report=product-audittrail&report_zone=products&report-type=product-audittrail&report_submit=1&numResults=500&productId=${row.original.bpId}`;
              return <ExternalLink href={url} content={row.value} />;
            }
          },
        },
        {
          Header: 'Brand',
          accessor: 'brandName',
          style: { textAlign: 'left' },
          width: 140,
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            return (
              rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
            );
          },
        },
        {
          Header: 'Vendor',
          accessor: 'vendor',
          style: { textAlign: 'left' },
          width: 100,
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            return (
              rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
            );
          },
        },
        {
          Header: 'Name',
          accessor: 'name',
          style: { textAlign: 'left' },
          width: 300,
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            return (
              rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
            );
          },
        },
        {
          Header: 'Retail',
          accessor: 'retail',
          width: 75,
          Cell: (row) => this.formatter.format(row.value),
        },
        {
          Header: 'DISC',
          accessor: 'avgDiscount',
          width: 75,
          Cell: (row) => {
            if (row.value) {
              return row.value + '%';
            }
          },
        },
        {
          Header: 'SCH',
          accessor: 'schedule',
          width: 40,
          style: { textAlign: 'center' },
          Filter: ({ filter, onChange }) =>
            this.scheduleFilter({ filter, onChange }),
          filterMethod: (filter, row) => {
            if (filter.value === 'all') {
              return true;
            } else {
              return row[filter.id] == filter.value;
            }
          },
        },
        {
          Header: 'M2',
          accessor: 'm2Enabled',
          width: 40,
          style: { textAlign: 'center' },
          Cell: (row) => {
            if (row.value) {
              return <CheckBoxIcon style={{ fontSize: 16 }} />;
            } else if (row.value == undefined) {
              return <CheckBoxOutlineBlankIcon style={{ fontSize: 16 }} />;
            }
          },
          Filter: ({ filter, onChange }) =>
            this.statusFilter({ filter, onChange }),
          filterMethod: (filter, row) => {
            if (filter.value === 'all') {
              return true;
            } else if (filter.value === 'Not Found') {
              return row[filter.id] == undefined;
            } else {
              return row[filter.id] != undefined;
            }
          },
        },
        {
          Header: 'FD',
          accessor: 'hasBeenFauxDemo',
          width: 40,
          style: { textAlign: 'center' },
          Cell: (row) => {
            if (row.value) {
              return <CheckBoxIcon style={{ fontSize: 16 }} />;
            } else if (row.value == undefined) {
              return <CheckBoxOutlineBlankIcon style={{ fontSize: 16 }} />;
            }
          },
          Filter: ({ filter, onChange }) =>
            this.statusFilter({ filter, onChange }),
          filterMethod: (filter, row) => {
            if (filter.value === 'all') {
              return true;
            } else if (filter.value === 'Not Found') {
              return row[filter.id] == undefined;
            } else {
              return row[filter.id] != undefined;
            }
          },
        },
      ],
    },
    {
      Header: 'Stock',
      headerClassName: 'blue-masterheader',
      columns: [
        {
          Header: () => (
            <div>
              <span data-tip data-for='wsh'>
                WSH
              </span>
              <ReactTooltip id='wsh' place='top' type='dark' effect='solid'>
                Wishlisted
              </ReactTooltip>
            </div>
          ),
          accessor: 'wishlists',
          headerClassName: 'blue-header',
          className: 'lblue',
          width: 45,
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            if (parseInt(filter.value) < 0) {
              return rowValue < parseInt(filter.value);
            }
            return rowValue > parseInt(filter.value);
          },
        },
        {
          Header: () => (
            <div>
              <span data-tip data-for='alrt'>
                ALRT
              </span>
              <ReactTooltip id='alrt' place='top' type='dark' effect='solid'>
                Stock Alerts
              </ReactTooltip>
            </div>
          ),
          accessor: 'stockAlerts',
          headerClassName: 'blue-header',
          className: 'lblue',
          width: 45,
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            if (parseInt(filter.value) < 0) {
              return rowValue < parseInt(filter.value);
            }
            return rowValue > parseInt(filter.value);
          },
        },
        {
          Header: () => (
            <div>
              <span data-tip data-for='cart'>
                CRT
              </span>
              <ReactTooltip id='cart' place='top' type='dark' effect='solid'>
                In Carts
              </ReactTooltip>
            </div>
          ),
          accessor: 'inCarts',
          headerClassName: 'blue-header',
          className: 'lblue',
          width: 45,
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            if (parseInt(filter.value) < 0) {
              return rowValue < parseInt(filter.value);
            }
            return rowValue > parseInt(filter.value);
          },
        },
        {
          Header: () => (
            <div>
              <span data-tip data-for='qty'>
                QTY
              </span>
              <ReactTooltip id='qty' place='top' type='dark' effect='solid'>
                BP On Hand
              </ReactTooltip>
            </div>
          ),
          accessor: 'bpOnHand',
          headerClassName: 'blue-header',
          className: 'blue',
          width: 45,
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            if (parseInt(filter.value) < 0) {
              return rowValue < parseInt(filter.value);
            }
            return rowValue > parseInt(filter.value);
          },
        },
        {
          Header: () => (
            <div>
              <span data-tip data-for='ord'>
                ORD
              </span>
              <ReactTooltip id='ord' place='top' type='dark' effect='solid'>
                On Order
              </ReactTooltip>
            </div>
          ),
          accessor: 'backorders',
          headerClassName: 'blue-header',
          className: 'blue',
          width: 45,
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            if (parseInt(filter.value) < 0) {
              return rowValue < parseInt(filter.value);
            }
            return rowValue > parseInt(filter.value);
          },
        },
        {
          Header: () => (
            <div>
              <span data-tip data-for='pre'>
                PRE
              </span>
              <ReactTooltip id='pre' place='top' type='dark' effect='solid'>
                Preordered
              </ReactTooltip>
            </div>
          ),
          accessor: 'preorders',
          headerClassName: 'blue-header',
          className: 'blue',
          width: 45,
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            if (parseInt(filter.value) < 0) {
              return rowValue < parseInt(filter.value);
            }
            return rowValue > parseInt(filter.value);
          },
        },
        {
          Header: () => (
            <div>
              <span data-tip data-for='tot'>
                TOT
              </span>
              <ReactTooltip id='tot' place='top' type='dark' effect='solid'>
                QTY + ORD - PRE
              </ReactTooltip>
            </div>
          ),
          accessor: 'saleable_inv',
          headerClassName: 'blue-header',
          className: 'blue',
          width: 45,
          style: { fontWeight: 'bold' },
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            if (parseInt(filter.value) < 0) {
              return rowValue < parseInt(filter.value);
            }
            return rowValue > parseInt(filter.value);
          },
        },
      ],
    },
    {
      Header: 'Rates',
      headerClassName: 'green-masterheader',
      columns: [
        {
          Header: () => (
            <div>
              <span data-tip data-for='doss'>
                Rate
              </span>
              <ReactTooltip id='doss' place='top' type='dark' effect='solid'>
                Avg monthly sales rate over the last 120 days of in-stock time
              </ReactTooltip>
            </div>
          ),
          accessor: 'rate',
          headerClassName: 'green-header',
          className: 'green',
          width: 75,
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            if (parseInt(filter.value) < 0) {
              return rowValue < parseInt(filter.value);
            }
            return rowValue > parseInt(filter.value);
          },
        },
        {
          Header: () => (
            <div>
              <span data-tip data-for='time'>
                Past30
              </span>
              <ReactTooltip id='time' place='top' type='dark' effect='solid'>
                # sales over the past 30 calendar days
              </ReactTooltip>
            </div>
          ),
          accessor: 'past30Sales',
          headerClassName: 'green-header',
          className: 'green',
          width: 75,

          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            if (parseInt(filter.value) < 0) {
              return rowValue < parseInt(filter.value);
            }
            return rowValue > parseInt(filter.value);
          },
        },
        {
          Header: () => (
            <div>
              <span data-tip data-for='conf'>
                CONF
              </span>
              <ReactTooltip id='conf' place='top' type='dark' effect='solid'>
                Confidence in the suggested order amount
              </ReactTooltip>
            </div>
          ),
          accessor: 'confidence',
          headerClassName: 'green-header',
          className: 'green',
          width: 75,
          Cell: (row) => {
            if (row.value) {
              return Math.round(row.value) + '%';
            }
          },
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            if (parseInt(filter.value) < 0) {
              return rowValue < parseInt(filter.value);
            }
            return rowValue > parseInt(filter.value);
          },
        },
      ],
    },
    {
      Header: () => (
        <div>
          <span data-tip data-for='orders'>
            Order
          </span>
          <ReactTooltip id='orders' place='top' type='dark' effect='solid'>
            Total - Flat Rate * Num Months
          </ReactTooltip>
        </div>
      ),
      headerClassName: 'orange-masterheader',
      columns: [
        {
          Header: '2m',
          accessor: 'order_2mo',
          headerClassName: 'orange-header',
          className: 'orange',
          width: 40,
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            if (parseInt(filter.value) < 0) {
              return rowValue < parseInt(filter.value);
            }
            return rowValue > parseInt(filter.value);
          },
        },
        {
          Header: '3m',
          accessor: 'order_3mo',
          headerClassName: 'orange-header',
          className: 'orange',
          width: 40,
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            if (parseInt(filter.value) < 0) {
              return rowValue < parseInt(filter.value);
            }
            return rowValue > parseInt(filter.value);
          },
        },
        {
          Header: '4m',
          accessor: 'order_4mo',
          headerClassName: 'orange-header',
          className: 'orange',
          width: 40,
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            if (parseInt(filter.value) < 0) {
              return rowValue < parseInt(filter.value);
            }
            return rowValue > parseInt(filter.value);
          },
        },
        {
          Header: '5m',
          accessor: 'order_5mo',
          headerClassName: 'orange-header',
          className: 'orange',
          width: 40,
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            if (parseInt(filter.value) < 0) {
              return rowValue < parseInt(filter.value);
            }
            return rowValue > parseInt(filter.value);
          },
        },
        {
          Header: '6m',
          accessor: 'order_6mo',
          headerClassName: 'orange-header',
          className: 'orange',
          width: 40,
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            if (parseInt(filter.value) < 0) {
              return rowValue < parseInt(filter.value);
            }
            return rowValue > parseInt(filter.value);
          },
        },

        {
          Header: 'MOQ',
          accessor: 'moq',
          headerClassName: 'orange-header',
          className: 'orange',
          width: 75,
          filterMethod: (filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            return (
              rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
            );
          },
        },
      ],
    },
    {
      Header: 'Date',
      headerClassName: 'cost-masterheader',
      columns: [
        {
          Header: () => (
            <div>
              <span data-tip data-for='stsls'>
                STSLS
              </span>
              <ReactTooltip id='stsls' place='top' type='dark' effect='solid'>
                Stock Time Since Last Sale
              </ReactTooltip>
            </div>
          ),
          accessor: 'stsls',
          width: 100,
        },
        {
          Header: '1st Arrived',
          accessor: 'firstArrivalDate',
          width: 100,
          Cell: (row) => (row.value ? row.value.split('T')[0] : null),
        },
        {
          Header: 'Last Arrived',
          accessor: 'arrivalDate',
          width: 100,
          Cell: (row) => (row.value ? row.value.split('T')[0] : null),
        },
        {
          Header: 'Created',
          accessor: 'createdAt',
          width: 100,
          Cell: (row) => (row.value ? row.value.split('T')[0] : null),
        },
      ],
    },
    {
      expander: true,
      Header: () => <strong>Chart</strong>,
      width: 80,
      Expander: ({ isExpanded, ...rest }) => {
        return (
          <div>{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
        );
      },
      getProps: (state, rowInfo, column) => {
        return {
          className: 'show-pointer',
        };
      },
      style: {
        fontSize: 25,
        padding: '0',
        textAlign: 'center',
        userSelect: 'none',
      },
    },
  ];

  componentDidMount() {
    this.props.fetchPurchasing().then((resp) => {
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    return (
      <div>
        {/* Table */}
        <ReactTable
          data={this.props.brightpearl.purchasing}
          columns={this.purchasingColumns}
          style={{ height: '750px', textAlign: 'right', fontSize: '14px' }}
          showPagination
          filterable
          defaultSorted={[
            { id: 'brandName', desc: false },
            { id: 'name', desc: false },
          ]}
          defaultfilterMethod={(filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            if (parseInt(filter.value) < 0) {
              return rowValue < parseInt(filter.value);
            }
            return rowValue > parseInt(filter.value);
          }}
          minRows={10}
          pageSize={100}
          className='-striped -highlight'
          loading={this.state.loading}
          loadingText='Loading...'
          expanded={this.state.expanded}
          onExpandedChange={(expanded, index, event) => {
            this.setState({ expanded });
          }}
          SubComponent={(row) => {
            return <SalesModal bpId={row.original.bpId} />;
          }}
        />
      </div>
    );
  }
}

function mapStateToProps({ brightpearl }) {
  return { brightpearl };
}
const mapDispatchToProps = {
  fetchPurchasing,
};
export default connect(mapStateToProps, mapDispatchToProps)(PurchasingTable);
