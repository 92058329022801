import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchAudit,
  fetchEtaAudit,
  fetchMagentoAudit,
  fetchBlacklistProducts,
  blacklistProduct,
  fetchCategories,
  fetchDiscontinuedAudit,
  fetchReverbAudit,
} from '../actions';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import PaperPage from './PaperPage';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ClearIcon from '@material-ui/icons/Clear';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import teal from '@material-ui/core/colors/teal';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import deepPurple from '@material-ui/core/colors/deepPurple';
import indigo from '@material-ui/core/colors/indigo';
import lightGreen from '@material-ui/core/colors/lightGreen';
import lightBlue from '@material-ui/core/colors/lightBlue';
import blueGrey from '@material-ui/core/colors/blueGrey';
import red from '@material-ui/core/colors/red';

const colorPriority = (priority) => {
  switch (priority) {
    case 'A':
      return blue['100'];
    case 'B':
      return lightBlue['100'];
    case 'C':
      return teal['100'];
    case 'D':
      return lightGreen['100'];
    case 'E':
      return green['100'];
    case 'F':
      return deepPurple['100'];
    case 'G':
      return indigo['100'];
    case 'H':
    default:
      return blueGrey['100'];
  }
};

class Audit extends Component {
  state = {
    tabs: 0,
    etaLoading: true,
    magentoLoading: true,
    discLoading: true,
    reverbLoading: true,
    submitModal: false,
    import: '',
  };

  componentDidMount() {
    this.props.fetchAudit();

    this.props.fetchEtaAudit().then((resp) => {
      this.setState({ etaLoading: false });
    });
    this.props.fetchMagentoAudit().then((resp) => {
      this.setState({ magentoLoading: false });
    });
    this.props.fetchDiscontinuedAudit().then((resp) => {
      this.setState({ discLoading: false });
    });
    this.props.fetchReverbAudit().then((resp) => {
      this.setState({ reverbLoading: false });
    });
    this.props.fetchBlacklistProducts();
    this.props.fetchCategories();
  }

  tabber = (event, value) => {
    this.setState({ tabs: value });
  };

  handleOpen = (e) => {
    this.setState({ import: e.original, submitModal: true });
  };

  handleClose = () => {
    this.setState({ import: '', submitModal: false });
  };

  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  auditColumns = [
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 125,
      style: {
        textAlign: 'left',
      },
    },
    {
      Header: 'Brand',
      accessor: 'brand',
      width: 150,
      style: {
        textAlign: 'left',
      },
    },
    {
      Header: 'Name',
      accessor: 'name',
      style: {
        textAlign: 'left',
      },
    },
    { Header: 'Type', accessor: 'type', width: 75 },
    { Header: 'Status', accessor: 'status', width: 75 },
    { Header: 'Action', accessor: 'action', width: 185 },
    { Header: 'Priority', accessor: 'priority', width: 75 },
    { Header: 'QTY', accessor: 'qty', width: 50 },
    {
      Header: 'OG Price',
      accessor: 'og_price',
      width: 85,
      style: {
        textAlign: 'right',
      },
      Cell: (row) => (row.value ? this.formatter.format(row.value) : ''),
    },
    {
      Header: 'Price',
      accessor: 'price',
      width: 85,
      style: {
        textAlign: 'right',
      },
      Cell: (row) => (row.value ? this.formatter.format(row.value) : ''),
    },
    {
      Header: 'Sp Price',
      accessor: 'special_price',
      width: 75,
      style: {
        textAlign: 'right',
      },
      Cell: (row) => (row.value ? this.formatter.format(row.value) : ''),
    },
    {
      Header: 'Disc',
      width: 50,
      Cell: (row) => {
        if (row.original.special_price) {
          return Math.round(
            (1 - row.original.special_price / row.original.price) * 100
          );
        } else {
          return null;
        }
      },
    },
    {
      Header: 'Blacklist',
      accessor: '_id',
      width: 75,
      Cell: (row) => (
        <Button
          style={{
            minHeight: 0,
            minWidth: 0,
            height: '100%',
            padding: '0 35px',
          }}
          onClick={() => this.handleOpen(row)}>
          <ClearIcon style={{ fontSize: 16 }} />
        </Button>
      ),
    },
  ];

  discontinuedColumns = [
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 125,
      style: {
        textAlign: 'left',
      },
    },
    {
      Header: 'Brand',
      accessor: 'brand',
      width: 150,
      style: {
        textAlign: 'left',
      },
    },
    {
      Header: 'Name',
      accessor: 'name',
      width: 200,
      style: {
        textAlign: 'left',
      },
    },
    {
      Header: 'BP Status',
      accessor: 'bp.bpStatus',
      width: 120,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'On Hand',
      accessor: 'bp.bpOnHand',
      width: 100,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'Visibility',
      accessor: 'visibility',
      style: {
        textAlign: 'left',
      },
    },
    {
      Header: 'Categories',
      accessor: 'category_ids',
      style: {
        textAlign: 'left',
      },
    },
    {
      Header: 'Action',
      accessor: 'action',
      style: {
        textAlign: 'left',
      },
    },
  ];
  magentoColumns = [
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 200,
    },
    {
      Header: 'Brand',
      accessor: 'brand',
      width: 200,
    },
    {
      Header: 'Name',
      accessor: 'name',
      width: 500,
    },
    { Header: 'Issue', accessor: 'issue', width: 400 },
  ];

  thirdPartyColumns = [
    { Header: 'SKU', accessor: 'sku', width: 100 },
    {
      Header: 'Brand',
      accessor: 'brand',
      width: 200,
      style: { textAlign: 'left' },
    },
    { Header: 'Name', accessor: 'name', style: { textAlign: 'left' } },
    {
      Header: 'ETA',
      accessor: 'stock_status_detail',
      style: { textAlign: 'left' },
    },
    {
      Header: 'Valid Date',
      accessor: 'validDate',
      style: { textAlign: 'center' },
      width: 100,
    },
    {
      Header: 'On Hand',
      accessor: 'bp.bpOnHand',
      style: { textAlign: 'left' },
      width: 100,
    },
    {
      Header: 'Preorders',
      accessor: 'bp.preorders',
      style: { textAlign: 'left' },
      width: 100,
    },
  ];

  blacklistColumns = [
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 200,
    },
    {
      Header: 'Name',
      accessor: 'bpName',
    },
  ];

  reverbAuditColumns = [
    { Header: 'SKU', accessor: 'sku', width: 200 },
    { Header: 'Name', accessor: 'bp.bpName' },
    { Header: 'Status', accessor: 'status', width: 200 },
    { Header: 'Message', accessor: 'msg' },
  ];

  categoriesColumns = [
    { Header: 'Magento Root', accessor: 'magentoRoot' },
    { Header: 'Magento Sub', accessor: 'magentoSub' },
    { Header: 'Algolia Index', accessor: 'index' },
    { Header: 'Reverb Uuid', accessor: 'reverbUuid', width: 100 },
    { Header: 'Reverb Sub', accessor: 'reverbRoot', width: 100 },
    { Header: 'Issue', accessor: 'issue', width: 300 },
  ];

  render() {
    const { tabs } = this.state;
    return (
      <PaperPage>
        {/* Header Bar */}
        <TopBar pageName='Stock Audit'>
          <TopBarRow justify='flex-end'>
            Downloads:
            <Button
              variant='contained'
              href={'/api/audit/stock/csv'}
              label='Stock Audit'
              style={{ marginLeft: 20 }}>
              Stock Audit
            </Button>
            <Button
              variant='contained'
              href={'/api/audit/magento/csv'}
              label='Magento Audit'
              style={{ marginLeft: 20 }}>
              Magento Audit
            </Button>
            <Button
              variant='contained'
              href={'/api/brightpearl/products'}
              label='Products'
              style={{ marginLeft: 20 }}>
              BP Products
            </Button>
            <Button
              variant='contained'
              href={'/api/magento/exportcatalog'}
              label='Catalog'
              style={{ marginLeft: 20 }}>
              M2 Catalog
            </Button>
            <Button
              variant='contained'
              href={'/api/magento/exportcatalog-lite'}
              label='Catalog'
              style={{ marginLeft: 20 }}>
              Lite M2 Catalog
            </Button>
          </TopBarRow>
        </TopBar>

        {/* Tabs + Tooltips */}
        <AppBar position='static' style={{ boxShadow: 'none' }}>
          <Tabs value={tabs} onChange={this.tabber}>
            <Tab data-tip data-for='listings' label='Listings' />
            <Tab data-tip data-for='magento' label='Magento' />
            <Tab data-tip data-for='discontinued' label='Discontinued' />
            <Tab data-tip data-for='eta' label='ETA' />
            <Tab data-tip data-for='categories' label='Categories' />
            <Tab data-tip data-for='reverb' label='Reverb' />
            <Tab data-tip data-for='blacklist-product' label='Blacklist' />
          </Tabs>
        </AppBar>

        <ReactTooltip id='listings' place='top' type='dark' effect='solid'>
          In stock + not live on Magento
        </ReactTooltip>
        <ReactTooltip id='magento' place='top' type='dark' effect='solid'>
          Live on Magento with issues
        </ReactTooltip>
        <ReactTooltip id='discontinued' place='top' type='dark' effect='solid'>
          Set to Discontinued on Magento
        </ReactTooltip>
        <ReactTooltip id='eta' place='top' type='dark' effect='solid'>
          Products with custom ETA stock status details
        </ReactTooltip>
        <ReactTooltip id='categories' place='top' type='dark' effect='solid'>
          Magento / Reverb category map
        </ReactTooltip>
        <ReactTooltip
          id='blacklist-product'
          place='top'
          type='dark'
          effect='solid'>
          Products that were deleted from the stock audit
        </ReactTooltip>

        {/* Blacklist Dialog */}
        <Dialog open={this.state.submitModal} onClose={this.handleClose}>
          <DialogTitle>{`Blacklist ${this.state.import.sku}?`}</DialogTitle>
          <DialogContent style={{ width: 500 }}>
            <DialogContentText>{this.state.import.name}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='secondary'>
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.props.blacklistProduct(this.state.import);
                this.handleClose();
              }}
              color='secondary'
              autoFocus>
              Blacklist
            </Button>
          </DialogActions>
        </Dialog>

        {/* Stock Audit */}
        {tabs === 0 && (
          <ReactTable
            data={this.props.audit}
            columns={this.auditColumns}
            style={{ textAlign: 'center' }}
            filterable
            defaultFilterMethod={(filter, row) => {
              let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
              return (
                rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
              );
            }}
            showPagination
            minRows={10}
            pageSize={100}
            className='-striped -highlight'
            getTrProps={(state, rowInfo, column) => {
              if (rowInfo)
                return {
                  style: { background: colorPriority(rowInfo.row.priority) },
                };
              else return {};
            }}
            getTdProps={() => ({
              style: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              },
            })}
          />
        )}

        {/* M2 Audit */}
        {tabs === 1 && (
          <ReactTable
            data={this.props.magentoAudit}
            columns={this.magentoColumns}
            style={{ textAlign: 'left' }}
            filterable
            defaultFilterMethod={(filter, row) => {
              let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
              return (
                rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
              );
            }}
            defaultSorted={[
              { id: 'issue', desc: false },
              { id: 'brand', desc: false },
            ]}
            loading={this.state.magentoLoading}
            loadingText='Loading...'
            showPagination
            minRows={10}
            pageSize={100}
            className='-striped -highlight'
          />
        )}

        {/* Discontinued */}
        {tabs === 2 && (
          <div>
            <div style={{ paddingLeft: 20 }}>
              <p style={{ fontWeight: 'bold' }}>Actions</p>
              <p>
                Most discontinuation updates should happen automatically
                overnight but if you see any rows with an action indicated
                please do the following:
              </p>
              <p>
                <span style={{ background: orange['100'], padding: '0px 5px' }}>
                  Set Live
                </span>{' '}
                Keep stock status as discontinued but reset visibility to
                "Catalog, Search" and set categories
              </p>
              <p>
                <span style={{ background: red['100'], padding: '0px 5px' }}>
                  Discontinue
                </span>{' '}
                Set visibility to "Catalog" and clear categories
              </p>
              <p>
                <span style={{ background: blue['100'], padding: '0px 5px' }}>
                  Archive
                </span>{' '}
                Set to archived on Brightpearl
              </p>
            </div>

            <ReactTable
              data={this.props.discontinued}
              columns={this.discontinuedColumns}
              showPagination
              filterable
              defaultSorted={[
                { id: 'action', desc: true },
                { id: 'bp.bpOnHand', desc: true },
                { id: 'brand', desc: false },
                { id: 'name', desc: false },
              ]}
              defaultFilterMethod={(filter, row) => {
                let rowValue =
                  row[filter.id] == undefined ? '' : row[filter.id];
                return (
                  rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) >
                  -1
                );
              }}
              getTrProps={(state, rowInfo, column) => {
                if (rowInfo && rowInfo.row) {
                  if (rowInfo.row.action == 'Discontinue') {
                    return { style: { background: red['100'] } };
                  } else if (rowInfo.row.action == 'Set Live') {
                    return { style: { background: orange['100'] } };
                  } else if (rowInfo.row.action == 'Archive') {
                    return { style: { background: blue['100'] } };
                  } else return {};
                } else {
                  return {};
                }
              }}
              minRows={10}
              pageSize={100}
              loading={this.state.discLoading}
              loadingText='Loading...'
              className='-striped -highlight'
            />
          </div>
        )}

        {/* Eta Audit */}
        {tabs === 3 && (
          <ReactTable
            data={this.props.etaAudit}
            columns={this.thirdPartyColumns}
            style={{ textAlign: 'center' }}
            defaultSorted={[{ id: 'brand', desc: false }]}
            showPagination
            filterable
            defaultFilterMethod={(filter, row) => {
              let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
              return (
                rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
              );
            }}
            loading={this.state.etaLoading}
            loadingText='Loading...'
            minRows={10}
            pageSize={100}
            className='-striped -highlight'
          />
        )}

        {/* Categories */}
        {tabs === 4 && (
          <ReactTable
            data={this.props.categories}
            columns={this.categoriesColumns}
            showPagination
            filterable
            defaultSorted={[{ id: 'issue', desc: true }]}
            defaultFilterMethod={(filter, row) => {
              let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
              return (
                rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
              );
            }}
            getTrProps={(state, rowInfo, column) => {
              if (
                rowInfo != undefined &&
                rowInfo.row.issue == '#NA - Algolia entry not found on Magento'
              )
                return { style: { background: red['100'] } };
              if (
                rowInfo != undefined &&
                rowInfo.row.issue == '#NA - Subcategory Not on Algolia'
              )
                return { style: { background: blue['100'] } };
              if (
                rowInfo != undefined &&
                rowInfo.row.issue == '#NA - Main Category Not on Algolia'
              )
                return { style: { background: blue['200'] } };
              else return {};
            }}
            minRows={10}
            pageSize={100}
            className='-striped -highlight'
          />
        )}

        {/* Reverb Audit */}
        {tabs === 5 && (
          <ReactTable
            data={this.props.reverbAudit}
            columns={this.reverbAuditColumns}
            showPagination
            defaultSorted={[
              { id: 'msg', desc: false },
              { id: 'bp.bpName', desc: false },
            ]}
            filterable
            minRows={10}
            pageSize={100}
          />
        )}

        {/* Blacklist Products */}
        {tabs === 6 && (
          <ReactTable
            data={this.props.blacklist}
            columns={this.blacklistColumns}
            showPagination
            defaultSorted={[{ id: 'bpName', desc: false }]}
            filterable
            minRows={10}
            pageSize={100}
            className='-blacklist'
          />
        )}
      </PaperPage>
    );
  }
}

function mapStateToProps({
  audit,
  etaAudit,
  magentoAudit,
  blacklist,
  categories,
  discontinued,
  reverbAudit,
}) {
  return {
    audit,
    etaAudit,
    magentoAudit,
    blacklist,
    categories,
    discontinued,
    reverbAudit,
  };
}
const mapDispatchToProps = {
  fetchAudit,
  fetchEtaAudit,
  fetchMagentoAudit,
  fetchBlacklistProducts,
  blacklistProduct,
  fetchCategories,
  fetchDiscontinuedAudit,
  fetchReverbAudit,
};
export default connect(mapStateToProps, mapDispatchToProps)(Audit);
