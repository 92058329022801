import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchConfigurables } from '../actions';
import ReactTable from 'react-table';
import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import Button from '@material-ui/core/Button';
import PaperPage from './PaperPage';

class Configurable extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    this.props.fetchConfigurables().then((resp) => {
      this.setState({ loading: false });
    });
  }

  configurableColumns = [
    {
      Header: 'Config',
      headerClassName: 'blue-masterheader',
      columns: [
        {
          Header: 'SKU',
          accessor: 'sku',
          width: 100,
          headerClassName: 'blue-header',
          className: 'lblue',
        },
        {
          Header: 'Brand',
          accessor: 'brand',
          width: 150,
          headerClassName: 'blue-header',
          className: 'lblue',
        },
        {
          Header: 'Name',
          accessor: 'name',
          width: 300,
          headerClassName: 'blue-header',
          className: 'lblue',
        },
        {
          Header: 'Status',
          accessor: 'status',
          width: 75,
          headerClassName: 'blue-header',
          className: 'lblue',
        },
        {
          Header: 'Stock',
          accessor: 'stock',
          width: 150,
          headerClassName: 'blue-header',
          className: 'lblue',
        },
      ],
    },
    {
      Header: 'Children',
      columns: [
        { Header: 'SKU', accessor: 'config.sku', width: 100 },
        {
          Header: 'Brand',
          accessor: 'config.brand',
          width: 150,
        },
        { Header: 'Name', accessor: 'config.name', width: 300 },
        { Header: 'Status', accessor: 'config.status', width: 75 },
        { Header: 'Stock', accessor: 'config.stock', width: 150 },
        { Header: 'Cable Len', accessor: 'config.cable_length_config' },
        { Header: 'Color', accessor: 'config.color' },
        { Header: 'Pk Size', accessor: 'config.pack_size' },
        { Header: 'Pk Size Cfg', accessor: 'config.pack_size_config' },
        { Header: 'Size', accessor: 'config.size' },
      ],
    },
  ];

  render() {
    return (
      <PaperPage maxWidth='90%'>
        <TopBar pageName='Configurable Products'>
          <TopBarRow justify='flex-end'>
            <Button
              variant='contained'
              href={'/api/magento/config/csv'}
              label='Export'
              style={{ top: -5, marginLeft: 30 }}>
              Export
            </Button>
          </TopBarRow>
        </TopBar>
        <ReactTable
          data={this.props.configurable}
          columns={this.configurableColumns}
          style={{ textAlign: 'left', border: 0, height: '1000px' }}
          defaultSorted={[{ id: 'brand', desc: false }]}
          showPagination
          filterable
          defaultFilterMethod={(filter, row) => {
            return (
              row[filter.id] &&
              filter.value &&
              row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) >
                -1
            );
          }}
          pageSize={100}
          className='-striped -highlight'
          loading={this.state.loading}
          loadingText='Loading...'
        />
      </PaperPage>
    );
  }
}

function mapStateToProps({ configurable }) {
  return { configurable };
}
const mapDispatchToProps = {
  fetchConfigurables,
};
export default connect(mapStateToProps, mapDispatchToProps)(Configurable);
