import React, { Component } from 'react';
import { connect } from 'react-redux';
import Arrivals from './Arrivals';
import Tracking from './Tracking';

import { tabQuery, columnQuery } from './custom-js';

import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReactTooltip from 'react-tooltip';
import PaperPage from './PaperPage';
import Button from '@material-ui/core/Button';

import FlightLandIcon from '@material-ui/icons/FlightLand';
import DownloadIcon from '@material-ui/icons/FileDownload';

class Receiving extends Component {
  state = { tabs: tabQuery(this) };

  tabber = (event, value) => {
    this.setState({ tabs: value });
  };
  render() {
    return (
      <PaperPage>
        {/* Header Bar */}
        <TopBar pageName='Receiving'>
          <TopBarRow justify='flex-end'>
            <Button
              variant='contained'
              href={'/api/arrivals/csv'}
              label='Export Arrivals'
              style={{ top: -12, marginLeft: 30 }}>
              Export Arrivals
              <DownloadIcon style={{ marginLeft: 10 }} />
            </Button>
          </TopBarRow>
        </TopBar>
        {/* Tabs + Tooltips */}
        <AppBar position='static' style={{ boxShadow: 'none' }}>
          <Tabs value={this.state.tabs} onChange={this.tabber}>
            <Tab data-tip data-for='tracking' label='Tracking' />
            <Tab data-tip data-for='arrivals' label='Arrivals' />
          </Tabs>
        </AppBar>

        <ReactTooltip id='tracking' place='top' type='dark' effect='solid'>
          Incoming Shipment
        </ReactTooltip>
        <ReactTooltip id='arrivals' place='top' type='dark' effect='solid'>
          Recent Arrivals
        </ReactTooltip>

        {this.state.tabs === 0 && <Tracking filter={columnQuery(this)} />}
        {this.state.tabs === 1 && <Arrivals filter={columnQuery(this)} />}
      </PaperPage>
    );
  }
}

export default connect()(Receiving);
