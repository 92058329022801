import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateBrands, syncCoupons, createBpBrand } from '../actions';
import ReactTable from 'react-table';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ReactTooltip from 'react-tooltip';

import MergeTypeIcon from '@material-ui/icons/MergeType';
import DownloadIcon from '@material-ui/icons/FileDownload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AddIcon from '@material-ui/icons/Add';

import LinearProgress from '@material-ui/core/LinearProgress';

class BrandForm extends Component {
  state = {
    responseModal: false,
    submitting: false,
    associateImport: '',
    createBpName: '',
    createBpVendorId: '',
    response: '',
  };
  handleAssociateInput = (e) => {
    this.setState({ associateImport: e.target.value });
  };

  handleNewBrandNameInput = (e) => {
    this.setState({ createBpName: e.target.value });
  };

  handleNewBrandVendorInput = (e) => {
    this.setState({ createBpVendorId: e.target.value });
  };

  handleClose = () => {
    this.setState({ response: '', responseModal: false });
  };

  handleCreateBpBrand = () => {
    if (this.state.createBpName.length > 0) {
      console.log(this.state);
      this.setState({ submitting: true });
      this.props
        .createBpBrand(this.state.createBpName, this.state.createBpVendorId)
        .then(() => {
          this.setState({
            submitting: false,
            createBpName: '',
            createBpVendorId: '',
            responseModal: true,
            response: this.props.bpBrand.response,
          });
        });
    }
  };

  handleUpdateBrand = () => {
    if (this.state.associateImport.length > 0) {
      console.log(this.state);
      this.setState({ submitting: true });
      this.props.updateBrands(this.state.associateImport).then(() => {
        this.setState({
          submitting: false,
          associateImport: '',
          responseModal: true,
          response: this.props.bpBrand.response,
        });
      });
    }
  };

  handleSyncCoupons = () => {
    this.setState({ submitting: true });
    this.props.syncCoupons().then(() => {
      this.setState({ submitting: false });
    });
  };

  render() {
    return (
      <div style={{ width: '100%', display: 'inline-block' }}>
        <Modal open={this.state.responseModal}>
          <Paper
            style={{
              position: 'absolute',
              top: `20%`,
              left: `calc(50% - 500px)`,
              width: 1000,
              height: 400,
              outline: 0,
              padding: 25,
            }}>
            <ReactTable
              data={this.state.response}
              columns={[
                { Header: 'Brand', accessor: 'name', width: 250 },
                { Header: 'BP ID', accessor: 'bpId', width: 250 },
                { Header: 'Vendor ID', accessor: 'bpVendorId' },
              ]}
              style={{ height: '300px' }}
              className='-striped -highlight'
            />
            <Button
              style={{ marginTop: 15 }}
              onClick={this.handleClose}
              variant='raised'
              color='secondary'>
              ok
            </Button>
          </Paper>
        </Modal>

        <div style={{ width: '50%', float: 'left' }}>
          <form
            style={{ padding: '25px 0 10px 25px' }}
            noValidate
            autoComplete='off'>
            <TextField
              value={this.state.createBpName}
              onChange={this.handleNewBrandNameInput}
              id='createbrand'
              label='Create New BP Brand'
              InputLabelProps={{ shrink: true }}
              placeholder='Name'
              margin='normal'
              style={{
                paddingBottom: 7,
                paddingRight: 25,
                width: '75%',
                boxSizing: 'border-box',
              }}
            />
            <TextField
              value={this.state.createBpVendorId}
              onChange={this.handleNewBrandVendorInput}
              id='vendor'
              InputLabelProps={{ shrink: true }}
              placeholder='Vendor Id (Optional)'
              margin='normal'
              style={{
                paddingBottom: 7,
                width: '25%',
                boxSizing: 'border-box',
              }}
            />
            <Button
              onClick={this.handleCreateBpBrand}
              variant='raised'
              disabled={this.state.submitting}
              style={{ marginRight: 15, background: '#34b4c9' }}>
              Create
              <AddIcon style={{ marginLeft: 10 }} />
            </Button>
            <Button
              data-tip
              data-for='export-button'
              variant='contained'
              href={'/api/brands/csv'}
              label='Export'
              style={{ marginRight: 15 }}>
              Export
              <DownloadIcon style={{ marginLeft: 10 }} />
            </Button>

            <Button
              data-tip
              data-for='sync-button'
              variant='contained'
              onClick={this.handleSyncCoupons}
              label='SyncCoupons'
              style={{ marginLeft: 15, background: '#f26322' }}>
              Sync Coupons
              <LocalOfferIcon style={{ marginLeft: 10 }} />
            </Button>

            <ReactTooltip
              id='export-button'
              place='top'
              type='dark'
              effect='solid'>
              Download the table below as a csv
            </ReactTooltip>
            <ReactTooltip
              id='refresh-button'
              place='top'
              type='dark'
              effect='solid'>
              Sync the brand + vendor info on BP to the Reactor database
            </ReactTooltip>
            <ReactTooltip
              id='sync-button'
              place='top'
              type='dark'
              effect='solid'>
              Refresh Magento's coupon rules according to the "MAP Status"
              column below
            </ReactTooltip>
          </form>

          <form
            style={{ paddingLeft: 25, paddingBottom: 25 }}
            noValidate
            autoComplete='off'>
            <TextField
              value={this.state.associateImport}
              onChange={this.handleAssociateInput}
              id='brand'
              label='Associate Brands'
              InputLabelProps={{ shrink: true }}
              placeholder='Comma sep list of bpId|vendorId'
              fullWidth
              margin='normal'
              style={{ marginBottom: 15 }}
            />
            <Button
              onClick={this.handleUpdateBrand}
              variant='raised'
              disabled={this.state.submitting}
              style={{ marginRight: 15, background: '#34b4c9' }}>
              Associate
              <MergeTypeIcon style={{ marginLeft: 10 }} />
            </Button>
          </form>
          {this.state.submitting && (
            <LinearProgress
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                zIndex: 999,
              }}
              color='secondary'
            />
          )}
        </div>
        <div
          style={{
            width: '50%',
            float: 'left',
            padding: '25px 25px 25px 40px',
            boxSizing: 'border-box',
            fontSize: '14px',
          }}>
          <h1>Creating a brand on BP</h1>
          <p>
            Each product needs to have a brand to associate with before it can
            be added to BP. To create a brand:
          </p>
          <ol>
            <li>
              Add the brand name to the field to the upper left (use the same
              name for BP and Magento)
            </li>
            <li>
              Add the vendor contact ID if available (hit up Matt or Harry for
              this)
            </li>
            <li>Click "Create"</li>
            <li>On Nu-2017 click Functions &gt; Refresh &gt; brands</li>
          </ol>
          <p>
            You can also associate a vendor with a brand after the fact using
            the field to the left
          </p>
        </div>
      </div>
    );
  }
}
function mapStateToProps({ bpBrand }) {
  return { bpBrand };
}
const mapDispatchToProps = {
  updateBrands,
  syncCoupons,
  createBpBrand,
};
export default connect(mapStateToProps, mapDispatchToProps)(BrandForm);
