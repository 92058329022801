import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux'; // give components ability to call action creators
import * as actions from '../actions'; // import all action creators from this folder (via star operator)

import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import Header from './Header';
import Home from './Home';
import UserList from './UserList';
import Integrations from './Integrations';
import Audit from './Audit';
import Receiving from './Receiving';
import Reverb from './Reverb';
import Preorders from './Preorders';
import Demo from './Demo';
import Overstocks from './Overstocks';
import Brands from './Brands';
import Ebay from './Ebay';
import TopSellers from './TopSellers';
import Purchasing from './Purchasing';
import Transfer from './Transfer';
import Related from './Related';
import Configurable from './Configurable';
import BoughtTogether from './BoughtTogether';
import UsedNewSku from './UsedNewSku';
import PriceGuide from './PriceGuide';

import Used from './Used';
import UsedInventoryReport from './UsedInventoryReport';

import 'react-table/react-table.css';
import './custom-styles.css'; // must be imported last in order to override component styles (i.e. react-table native styles)
import StockCorrections from './StockCorrections';

const Loading = () => <h2 style={{ textAlign: 'center' }}>Loading</h2>;
const Login = () => <h2 style={{ textAlign: 'center' }}>Login first please</h2>;
const Restricted = () => (
  <h2 style={{ textAlign: 'center' }}>Admin access required</h2>
);
const userIsAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/login',
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: (state) => {
    switch (state.auth) {
      case null: // waiting for response
      case false: //logged out
        return false;
      default:
        return state.auth.confirmed;
    }
  },
  authenticatingSelector: (state) => state.auth == null,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated',
  AuthenticatingComponent: Loading,
  allowRedirectBack: false,
});

const userIsAdmin = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/restricted',
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: (state) => {
    switch (state.auth) {
      case null: // waiting for response
      case false: //logged out
        return false;
      default:
        // console.log(state.auth.admin === true);
        return state.auth.admin === true;
    }
  },
  authenticatingSelector: (state) => state.auth == null,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAdmin',
  AuthenticatingComponent: Loading,
  allowRedirectBack: false,
});

class App extends Component {
  componentDidMount() {
    this.props.fetchUser();
  }

  render() {
    return (
      <div className='container'>
        <BrowserRouter>
          <div>
            <Header />
            <div className='pageContent'>
              <div className='page'>
                <Route exact path='/' component={Home} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/restricted' component={Restricted} />
                <Route
                  path='/integrations'
                  component={userIsAdmin(Integrations)}
                />
                <Route path='/users' component={userIsAdmin(UserList)} />
                <Route path='/audit' component={userIsAuthenticated(Audit)} />
                <Route
                  path='/receiving'
                  component={userIsAuthenticated(Receiving)}
                />
                <Route path='/reverb' component={userIsAuthenticated(Reverb)} />
                <Route
                  path='/preorders'
                  component={userIsAuthenticated(Preorders)}
                />

                <Route path='/demo' component={userIsAuthenticated(Demo)} />
                <Route
                  path='/sale-calc'
                  component={userIsAuthenticated(Overstocks)}
                />
                <Route path='/brands' component={userIsAuthenticated(Brands)} />
                <Route path='/ebay' component={userIsAuthenticated(Ebay)} />
                <Route
                  path='/top-sellers'
                  component={userIsAuthenticated(TopSellers)}
                />
                <Route
                  path='/purchasing'
                  component={userIsAuthenticated(Purchasing)}
                />
                <Route
                  path='/price-guide'
                  component={userIsAuthenticated(PriceGuide)}
                />
                <Route
                  path='/transfer'
                  component={userIsAuthenticated(Transfer)}
                />
                <Route
                  path='/related'
                  component={userIsAuthenticated(Related)}
                />
                <Route
                  path='/configurable'
                  component={userIsAuthenticated(Configurable)}
                />
                <Route
                  path='/stock-corrections'
                  component={userIsAuthenticated(StockCorrections)}
                />
                <Route path='/used' component={userIsAuthenticated(Used)} />
                <Route
                  path='/used-new-sku'
                  component={userIsAuthenticated(UsedNewSku)}
                />

                <Route
                  path='/used-inventory-report'
                  component={userIsAuthenticated(UsedInventoryReport)}
                />
              </div>
            </div>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default connect(null, actions)(App);
