import {
  FETCH_SALECALC,
  FETCH_OVERSTOCKS,
  FETCH_PRICEGUIDE,
} from '../actions/types';

import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_SALECALC:
      return action.payload;
    case FETCH_OVERSTOCKS:
      return action.payload;
    case FETCH_PRICEGUIDE:
      return action.payload;
  }
}, []);
