import React from 'react';
import PaperPage from './PaperPage';
import './custom-styles.css'; // must be imported last in order to override component styles (i.e. react-table native styles)

const Index = () => {
  const flexStyle = {
    flex: 1,
    padding: '0 80px 10px 80px',
    margin: '5px',
  };
  return (
    <PaperPage class='dash'>
      <h1
        className='react-header'
        style={{ textAlign: 'center', paddingTop: 20, marginBottom: 10 }}>
        Reactor
      </h1>
      <div
        className='dash'
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}>
        <div style={flexStyle}>
          <h2>Product</h2>
          <h3>
            <a href='audit'>Stock Audits</a>
          </h3>
          <p>View items with issues on magento and reverb</p>
          <h3>
            <a href='brands'>Brands</a>
          </h3>
          <p>Create new BP / M2 brands + set blacklist attributes</p>
          <h3>
            <a href='reverb'>Reverb</a>
          </h3>
          <p>View and update Reverb products</p>
          <h3>
            <a href='related'>Related Products</a>
          </h3>
          <p>
            Add upsell relationships, view current connections, and get recs
          </p>
          <h3>
            <a href='configurable'>Config Products</a>
          </h3>
          <p>View current configurable relationships</p>
          <h2>Used</h2>
          <h3>
            <a href='used'>Intake</a>
          </h3>
          <p>Create used POs + view item status</p>
          <h3>
            <a href='used-inventory-report'>Inventory Report</a>
          </h3>
          <p>View used stock by warehouse</p>
          <h3>
            <a href='used-new-sku'>New SKUs</a>
          </h3>
          <p>View + associate new SKUs to used products</p>
        </div>

        <div style={flexStyle}>
          <h2>Warehouse</h2>
          <h3>
            <a href='receiving'>Receiving</a>
          </h3>
          <p>View incoming shipments + recent arrivals</p>
          <h3>
            <a href='transfer'>Transfer</a>
          </h3>
          <p>Transfer product between warehouses</p>
          <h3>
            <a href='preorders'>Preorders</a>
          </h3>
          <p>View + allocate preorders</p>
          <h3>
            <a href='stock-corrections'>Stock Corrections</a>
          </h3>
          <p>View past stock corrections</p>

          <h2>Sales</h2>
          <h3>
            <a href='price-guide'>Price Guide</a>
          </h3>
          <p>View past + current sale pricing</p>
          <h3>
            <a href='sale-calc'>Sale Calculator</a>
          </h3>
          <p>View sale recommendations + overstocks</p>
          <h3>
            <a href='purchasing'>Purchasing</a>
          </h3>
          <p>Buy shit</p>
          <h3>
            <a href='demo'>Demo Daemon</a>
          </h3>
          <p>View live demos + faux demo recommendations</p>
          <h3>
            <a href='top-sellers'>Top Sellers</a>
          </h3>
          <p>View top sellers + check if they're in the showroom</p>
        </div>
      </div>
    </PaperPage>
  );
};

export default Index;
